<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-sm-12">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Become a Free Agent</h3>
              <form @submit.prevent="onSubmit()">
                <div class="mb-3">
                  <label for="firstName" class="form-label">First Name</label>
                  <input name="firstName" type="text" class="form-control" id="firstName" placeholder="Enter First Name" v-model="form.firstName.value" @blur="markAsDirty('firstName')" />
                  <span class="error" v-if="form.firstName.isDirty">First Name is required</span>
                </div>
                <div class="mb-3">
                  <label for="lastName" class="form-label">Last Name</label>
                  <input name="lastName" type="text" class="form-control" id="lastName" placeholder="Enter Last Name" v-model="form.lastName.value" @blur="markAsDirty('lastName')" />
                  <span class="error" v-if="form.lastName.isDirty">Last Name is required</span>
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <input name="email" type="email" class="form-control" id="email" placeholder="Enter Email"  v-model="form.email.value" @blur="markAsDirty('email')" />
                  <span class="error" v-if="!form.email.value && form.email.isDirty">Email is required</span>
                  <span class="error" v-if="form.email.value && !form.email.isValid">Email is invalid</span>
                </div>
                <div class="mb-4">
                  <label for="phone" class="form-label">Phone</label>
                  <input name="phone" type="tel" class="form-control" id="phone" placeholder="Enter Phone" v-model="form.phone.value" @blur="markAsDirty('phone')" />
                  <span class="error" v-if="!form.phone.value && form.phone.isDirty">Phone is required</span>
                  <span class="error" v-if="form.phone.value && !form.phone.isValid">Phone must be an US number</span>
                </div>
                <div class="mb-3">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" id="male" value="male" v-model="form.gender.value" @change="markAsDirty('gender')" />
                    <label class="form-check-label" for="male">
                      <img src="@/assets/male.png" alt="male" class="gender" title="Male">
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gender" id="female" value="female" v-model="form.gender.value" @change="markAsDirty('gender')" />
                    <label class="form-check-label" for="female">
                      <img src="@/assets/female.png" alt="female" class="gender" title="Female">
                    </label>
                  </div>
                </div>
                <span class="error" v-if="form.gender.isDirty">Gender is required</span>
                <div class="mt-4 mb-3">
                  <fieldset class="position-experience-wrapper mb-4" v-for="(data, index) in form.positions" :key="data.id">
                    <div class="position mb-3">
                      <div class="position-title-btn-wrapper">
                        <label for="position" class="form-label">Position</label>
                        <div v-if="index > 0" class="remove-position-experience">
                          <a href="javascript:;" class="btn btn-default" @click="decreasePosition(index)"><i class="fa fa-minus"></i></a>
                        </div>
                      </div>
                      <select :id="`position${index}`" class="form-select" v-model="form.positions[index].position.value" @blur="markAsDirty('position', index)" @change="markAsDirty('position', index)">
                        <option value="" selected disabled>Select Position</option>
                        <option v-for="data in positionsData" :key="data.position" :value="data.position">{{ data.position }}</option>
                      </select>
                      <span class="error" v-if="form.positions[index].position.isDirty">Position is required</span>
                    </div>
                    <div class="experience">
                      <label for="experience" class="form-label">Experience</label>
                      <select :id="`experience${index}`" class="form-select"  v-model="form.positions[index].experience.value" @blur="markAsDirty('experience', index)" @change="markAsDirty('experience', index)">
                        <option value="" selected disabled>Select Experience</option>
                        <option v-for="data in skillsData" :key="data.position" :value="data.skill">{{ data.skill }}</option>
                      </select>
                      <span class="error" v-if="form.positions[index].experience.isDirty">Experience is required</span>
                    </div>
                  </fieldset>
                </div>
                <div class="add-position-experience">
                  <a href="javascript:;" class="btn btn-default" @click="increasePosition()"><i class="fa fa-plus"></i></a>
                </div>
                <div class="mt-3 mb-3">
                  <label for="arena" class="form-label">Arena</label>
                  <select name="arena" id="arena" class="form-select" multiple  v-model="form.arena.value" @blur="markAsDirty('arena')" @change="markAsDirty('arena', index)">
                    <option value="" selected disabled>Select Arena</option>
                    <option v-for="data in arenasData" :key="data.position" :value="data.name">{{ data.name }}</option>
                  </select>
                  <span class="error" v-if="form.arena.isDirty">Arena is required</span>
                </div>
                <div class="submit-wrapper">
                  <button class="btn btn-primary" :disabled="isDisabled">
                    <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    <span v-else>Enter Free Agency</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: "BecomeFreeAgent",
  data() {
    return {
      form: {
        firstName: {
          isValid: false,
          isDirty: false,
          value: ''
        },
        lastName: {
          isValid: false,
          isDirty: false,
          value: ''
        },
        email: {
          isValid: false,
          isDirty: false,
          value: ''
        },
        phone: {
          isValid: false,
          isDirty: false,
          value: ''
        },
        gender: {
          isValid: false,
          isDirty: false,
          value: 'male'
        },
        positions: [{
          id: Date.now(),
          position: {
            isValid: false,
            isDirty: false,
            value: ''
          },
          experience: {
            isValid: false,
            isDirty: false,
            value: ''
          }
        }],
        arena: {
          isValid: false,
          isDirty: false,
          value: []
        },
      },
      isFormValid: false,
      baseURL: process.env.VUE_APP_BASE_URL,
      positionsData: [],
      skillsData: [],
      arenasData: [],
      isDisabled: false
    };
  },
  mounted() {
    this.fetchPositions();
    this.fetchSkills();
    this.fetchArenas();
  },
  methods: {
    markAsDirty(fieldName, index = null) {
      if (fieldName === 'position' || fieldName === 'experience') {
        this.form.positions[index][fieldName].isDirty = !this.form.positions[index][fieldName].value;
      } else if (fieldName === 'arena') {
        this.form[fieldName].isDirty = !this.form[fieldName].value.length;
      } else {
        this.form[fieldName].isDirty = !this.form[fieldName].value;
      }
      this.validateField(fieldName, index);
    },
    validateField(fieldName, index = null) {
      if (fieldName === 'email') {
        if (this.form[fieldName].value) {
          const regex = new RegExp('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$');
          this.form[fieldName].isValid = regex.test(this.form[fieldName].value);
          this.form[fieldName].isDirty = false;
        } else {
          this.form[fieldName].isValid = false;
          this.form[fieldName].isDirty = true;
          this.isFormValid = false;
        }
      } else if (fieldName === 'phone') {
        if (this.form[fieldName].value) {
          const regex = new RegExp('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$');
          this.form[fieldName].isValid = regex.test(this.form[fieldName].value);
          this.form[fieldName].isDirty = false;
        } else {
          this.form[fieldName].isValid = false;
          this.form[fieldName].isDirty = true;
          this.isFormValid = false;
        }
      } else if (fieldName === 'position' || fieldName === 'experience') {
        if (this.form.positions[index][fieldName].value) {
          this.form.positions[index][fieldName].isValid = true;
          this.form.positions[index][fieldName].isDirty = false;
        } else {
          this.form.positions[index][fieldName].isValid = false;
          this.form.positions[index][fieldName].isDirty = true;
          this.isFormValid = false;
        }
      } else if (fieldName === 'arena') {
        if (this.form[fieldName].value.length) {
          this.form[fieldName].isValid = true;
          this.form[fieldName].isDirty = false;
        } else {
          this.form[fieldName].isValid = false;
          this.form[fieldName].isDirty = true;
          this.isFormValid = false;
        }
      } else {
        if (this.form[fieldName].value) {
          this.form[fieldName].isValid = true;
          this.form[fieldName].isDirty = false;
        } else {
          this.form[fieldName].isValid = false;
          this.form[fieldName].isDirty = true;
          this.isFormValid = false;
        }
      }
    },
    validateForm() {
      this.isFormValid = true;
      Object.keys(this.form).forEach((fieldName) => {
        if (fieldName === 'positions') {
          this.form[fieldName].forEach((positions, index) => {
            this.validateField('position', index);
            this.validateField('experience', index);
          });
        } else {
          this.validateField(fieldName);
        }
      });
    },
    increasePosition() {
      this.form.positions.push({
        id: Date.now(),
        position: {
          isValid: false,
          isDirty: false,
          value: ''
        },
        experience: {
          isValid: false,
          isDirty: false,
          value: ''
        }
      })
    },
    decreasePosition(index) {
      this.form.positions = this.form.positions.filter((_, positionsIndex) => positionsIndex !== index);
    },
    fetchPositions() {
      fetch(`${this.baseURL}positions`)
        .then(response => response.json())
        .then(data => {
          this.positionsData = data;
        });
    },
    fetchSkills() {
      fetch(`${this.baseURL}skills`)
        .then(response => response.json())
        .then(data => {
          this.skillsData = data;
        });
    },
    fetchArenas() {
      fetch(`${this.baseURL}arenas`)
        .then(response => response.json())
        .then(data => {
          this.arenasData = data;
        });
    },
    onSubmit() {
      this.validateForm();
      if (this.isFormValid) {
        this.isDisabled = true;
        const data = {};
        Object.keys(this.form).forEach((fieldName) => {
          if (fieldName === 'positions') {
            data[fieldName] = [];
            this.form[fieldName].forEach((positions) => {
              data[fieldName].push({
                position: positions['position'].value,
                experience: positions['experience'].value
              });
            });
          } else if (fieldName === 'arena') {
            data[fieldName] = this.form[fieldName].value.map((value) => value);
          } else {
            data[fieldName] = this.form[fieldName].value;
          }
        });
        fetch(`${this.baseURL}users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(response => response.json())
          .then(() => {
            this.isDisabled = false;
            this.$toast.success('Successfully entered free agency!', {
              position: 'top-right',
              duration: 3000
            });
            this.$router.push('/');
          })
          .catch((error) => {
            this.isDisabled = false;
            this.$toast.error(error.message || 'Something went wrong. Please try again later!', {
              position: 'top-right',
              duration: 3000
            });
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>